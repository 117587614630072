<template>
    <div class="w-3/4 mx-auto block text-center">
        <p>{{ data.Description }}</p>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    }
}
</script>
